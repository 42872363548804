div a {
  color: white;
  text-decoration: none;
}

.divider {
  margin: 0em 0em 2em 0em;
  border: 1px solid rgb(30, 30, 30)
}

#toggle {
  display: block;
  position: absolute;
  inset: 0 auto auto 0;
  height: 3rem;
  aspect-ratio: 1;
  background-color: rgb(30, 30, 30);
  color: white;
  border: 2px solid rgb(60, 60, 60);
  border-radius: 0.5rem;
  font-size: 2em;
  cursor: pointer;
}

#side-toggle {
  display: none;
  position: fixed;
  cursor: pointer;
  right: 21.5rem;
  top: 0.8rem;
  z-index: 2;
  color: rgb(100, 100, 100);
  user-select: none;
  scale: 2;
  background-color: rgb(10, 10, 10);
  margin: 0em -1em 0em 0em;
  padding: .1em .75em .1em .1em;
  border-radius: .25em 0 0 .25em;
}

#side-toggle:hover {
  color: rgb(220, 220, 220);
}

#scroll-container {
  height: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  overflow-y: auto;
}

#sidebar {
  position: fixed;
  inset: 0 0 auto auto;
  background-color: rgb(20, 20, 20);
  height: 100%;
  width: 20em;
  max-width: 95%;
  transition: transform;
  transition-duration: 0.2s;
  border-radius: 0.5em 0 0 0.5em;
  overflow: hidden;
  filter: drop-shadow(0 0.5em 0.5em black);
  z-index: 2;
  transform: translate(100%);
}

.list-item {
  display: block;
  position: relative;
  width: 100%;
  background-color: rgb(40, 40, 40);
  margin-top: 3px;
  text-align: center;
  padding: 1.5em 0em;
  user-select: none;
  cursor: pointer;
}

.hoverable:hover {
  filter: brightness(120%);
}

.list-start {
  width: 100%;
}

.list-end {
  width: 100%;
  margin-top: auto;
  background-color: rgb(15, 15, 15);
}

.ad {
  height: 10em;
  background-image: url("https://images.unsplash.com/photo-1559163179-3fd017552229?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80");
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 2em;
  padding-top: 0em;
}

.shopping-cart {
  position: relative;
  background-color: rgb(50, 150, 50);
  padding: 2em 0em 4.5em;
  margin: 0;
}

.user-image-container {
  position: absolute;
  top: 50%;
  transform: translate(50%,-50%);
}

.user-image {
  height: 40px;
  border-radius: 100px;
}

#item-count {
  color: rgb(10, 40, 10);
}

#proceed-to-check-out {
  position: absolute;
  width: 100%;
  bottom: 0.5em;
  left: 0;
  color: rgb(10, 50, 10);
  border-top: 2px solid rgb(10, 50, 10);
}

#list-icon {
  position: absolute;
  top: 0.35em;
  left: 0.35em;
  ;
  font-size: 3em;
  filter: grayscale(100%);
}

#overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: rgb(15, 15, 15);
}

::-webkit-scrollbar-track {
  border-radius: 1em;
}

::-webkit-scrollbar-thumb {
  background: rgb(60, 60, 60);
  border-radius: 1em;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(80, 80, 80);
}