.custom-navbar {
  position: fixed !important;
  z-index: 2;
  width: 100%;
}

.custom-navbar-user-login {
  border-radius: 100px;
  height: 20px;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -10px;
}

.dropdown-submenu:hover .dropdown-menu {
  display: block;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 98% !important;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}

.large-screen {
  white-space: nowrap;
}

@media only screen and (min-width: 875px) {
  .small-screen {
    display: none !important;
  }
}

@media only screen and (max-width: 875px) {
  .large-screen {
    display: none !important;
  }
}

@media only screen and (max-width: 375px) {
  .small-screen {
    display: none !important;
  }
}

@media only screen and (max-width: 1080px) {
  .large-screen-login {
    display: none !important;
  }
}

@media only screen and (max-width: 500px) {
  .large-screen-category {
    display: none !important;
  }
}

@media only screen and (min-width: 500px) {
  .small-screen-category {
    display: none !important;
  }
}

#sidebar-toggle {
  position: relative;
  scale: 2;
  transform: translate(0%, -.1rem);
}