.horizontal-list-container {
    flex-wrap: nowrap !important;
    overflow-y: hidden !important;
}

.scroll-button {
    display: flex;
    position: absolute; 
    top: 50%; 
    transform: translate(0, -50%); 
    background: rgba(50,50,50, .8);
    border: 2px solid rgb(200,200,200); 
    border-radius: .5em; 
    width: 3em;
    height: 3em;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: white;
    cursor: pointer;
    user-select: none;
    filter: drop-shadow(0 0 .3rem black)
}

.scroll-button-left {
    left: 10px;
} 

.scroll-button-right {
    right: 10px;
} 

.scroll-button:hover {
    filter: brightness(120%) drop-shadow(0 0 0 black);
}