.slider-container {
    height: 50px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .slider {
    position: relative;
    width: 90%;
  }
  
  .slider__track,
  .slider__range,
  .slider__left-value,
  .slider__right-value {
    position: absolute;
  }
  
  .slider__track,
  .slider__range {
    border-radius: 3px;
    height: 5px;
  }
  
  .slider__track {
    background-color: #ced4da;
    width: 100%;
    z-index: 1;
  }
  
  .slider__range, .slider__range-disabled {
    background-color: #4294ff;
    z-index: 2;
  }
  
  .slider__left-value,
.slider__right-value {
  width: 5em;
  margin-top: 1em;
  text-align: center;
  border: none;
  border-bottom: 1px solid #ced4da;
  background-color: transparent;
  color: #dee2e6;
  font-size: 1em;
}

.slider__left-value:disabled,
.slider__right-value:disabled, .slider__range:disabled, 
.thumb:disabled, .thumb--left:disabled, .thumb--right:disabled,
.slider__range-disabled {
  filter: brightness(0.5);
  user-select: none;
}

.slider__left-value {
  left: -.5em;
}

.slider__right-value {
  right: -.5em;
}
  
  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 90%;
    outline: none;
  }
  
  .thumb--left {
    z-index: 3;
  }
  
  .thumb--right {
    z-index: 4;
  }
  
  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  
  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }