
.left-fade {
    position: absolute;
    content: "";
    width: 1em;
    height: calc(100% - 10px);
    left: 0;
    background-image: linear-gradient(to left, rgba(30,30,30,0), rgba(30,30,30, 1) 80%);
    z-index: 1;
    pointer-events: none;
}

.right-fade {
    position: absolute;
    content: "";
    width: 1em;
    height: calc(100% - 10px);
    right: 0;
    background-image: linear-gradient(to right, rgba(30,30,30,0), rgba(30,30,30, 1) 80%);
    z-index: 1;
    pointer-events: none;
}

.flex-category-button:hover {
     background-color: rgb(50,50,50);
}